@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Hind', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Expletus Sans', cursive;
}

.backlit-button-wrapper {
  position: relative;
  display: inline-block;
}

.backlit-button-wrapper::before {
  content: '';
  position: absolute;
  top: -4px;
  left: -4px;
  right: -4px;
  bottom: -4px;
  background: radial-gradient(circle, rgba(255, 255, 0, 0.8) 0%, rgba(255, 255, 0, 0) 70%);
  border-radius: 9999px; /* Ensure it's fully rounded */
  z-index: -1;
  filter: blur(8px);
  opacity: 0.7;
  transition: opacity 0.3s ease, filter 0.3s ease;
}

.backlit-button-wrapper:hover::before {
  opacity: 1;
  filter: blur(12px);
}

/* Ensure the button itself remains above the backlight */
.backlit-button-wrapper .btn {
  position: relative;
  z-index: 1;
}
.ticker-wrap {
  width: 80%;
  margin: 0 auto;
  overflow: hidden;
  height: 2rem;
  background-color: transparent;
  position: relative;
}

.ticker {
  display: inline-block;
  height: 2rem;
  line-height: 2rem;
  white-space: nowrap;
  animation: ticker 30s linear infinite;
}

.ticker-content {
  display: inline-block;
  padding: 0 1rem;
  font-size: 1rem;
  color: #333;
}

@keyframes ticker {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-25%); /* Adjust this value based on the length of your content */
  }
}

.ticker-blur-left,
.ticker-blur-right {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 57%;
  pointer-events: none;
  z-index: 1;
}

.ticker-blur-left {
  left: 0;
  background: linear-gradient(to right, 
    #F8FAF9 0%, 
    #F8FAF9 60%,
    rgba(248, 250, 249, 0) 100%
  );
}

.ticker-blur-right {
  right: 0;
  background: linear-gradient(to left, 
    #F8FAF9 0%, 
    #F8FAF9 60%,
    rgba(248, 250, 249, 0) 100%
  );
}