/* src/fonts.css */

/* Expletus Sans Variable Font */
@font-face {
    font-family: 'Expletus Sans';
    src: url('./Fonts/ExpletusSans-VariableFont_wght.ttf') format('truetype-variations');
    font-weight: 400 700;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Expletus Sans';
    src: url('./Fonts/ExpletusSans-Italic-VariableFont_wght.ttf') format('truetype-variations');
    font-weight: 400 700;
    font-style: italic;
  }
  
  /* Hind Font */
  @font-face {
    font-family: 'Hind';
    src: url('./Fonts/Hind-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Hind';
    src: url('./Fonts/Hind-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Hind';
    src: url('./Fonts/Hind-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Hind';
    src: url('./Fonts/Hind-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Hind';
    src: url('./Fonts/Hind-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
  }